var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "development-permissions"
  }, [_c('b-card', [_c('div', {
    staticClass: "permissions"
  }, [_c('vs-permission-editor')], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }