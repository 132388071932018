<template>
  <div class="development-permissions">

    <b-card>
      <div class="permissions">
        <vs-permission-editor />
      </div>
    </b-card>

  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable no-unreachable */
import { BCard } from 'bootstrap-vue';
import VsPermissionEditor from '@/components/ui/vs-permission-editor/VsPermissionEditor.vue';

export default {
  name: 'DevelopmentPermissions',
  setup() {
  },
  components: {
    BCard,
    VsPermissionEditor,
  },
};
</script>

<style lang="sass" scoped>
.permissions
  position: relative

.removeme
  position: fixed
  right: 10%
  overflow-y: auto
  max-height: 90vh
  display: flex
  flex-direction: column
  gap: 10px

  .perm
    display: flex
    align-items: center
    gap: 10px

.perm-header
  display: flex
  align-items: center
  gap: 15px
  padding: 5px 0

span
  cursor: pointer

  &:hover
    opacity: 0.7

  &.enabled
    color: green
  &.disabled
    color: red
</style>
